import { createContext } from 'react';

import AuthStore from '@/store/Auth';
import UserStore from '@/store/User';

export const context = {
  AuthStore: new AuthStore(),
  UserStore: new UserStore(),
};

export default createContext(context);
