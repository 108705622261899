import { useState, useEffect } from 'react';

// 语言包定义，可以外部引入
const languagePack: any = {
  'zh-CN': {
    '广告精准触达，效果看得见！': null,
    '精准投放，智慧营销，让每一次广告展现都能创造价值。': null,
    立即接入: null,
    每日超过千亿次访问: null,
    全端流量覆盖: null,
    定制属于你的解决方案: null,
    欢迎通过如下渠道与我们达成联系: null,
    智能评估: null,
    通过大数据智能评估为您提供精准的分析与全面的决策支持: null,
    专属定制: null,
    '贴合需求与个性化方案，专属定制让每一个细节完美呈现': null,
    渠道整合: null,
    '全面整合多元渠道，提升品牌曝光与市场影响力': null,
    高效分发: null,
    '快速高效的分发机制，让信息精准到达目标受众': null,
    全端适配: null,
    '兼容各大平台，全端适配让用户体验无缝衔接': null,
    智能优化: null,
    '利用智能算法实时优化，提升投放效果与资源利用': null,
    数据分析: null,
    '深度数据分析，洞察市场动态，助您做出明智决策': null,
    灵活投放: null,
    '灵活投放策略，精准触达目标用户，提升转化效果': null,
    精准用户: null,
    '精准定位用户群体，优化资源配置，提升业务效果': null,
    金牌客服: null,
    '金牌客服团队，提供贴心服务，助力客户满意度提升': null,
    多渠道流量整合: null,
    '多个广告渠道的流量整合，提高广告触达率与曝光度。': null,
    精准流量分发: null,
    '利用智能算法和用户行为数据，精准流量定向分发。': null,
    实时数据监控: null,
    '提供实时的流量监控与数据分析功能，实时反馈优化策略。': null,
    灵活的广告配置: null,
    '支持多种广告格式和流量分发策略，灵活配置投放规则。': null,
    注册账户: null,
    创建活动: null,
    任意扩展: null,
    完成接入: null,
    快速接入: null,
    仅需简单几步即可完成流量快速接入: null,
    立即注册: null,
    '即刻起立即接入, 为业务强劲赋能': null,
    退出: null,
    首页: null,
    登录: null,
    注册: null,
    总览: null,
    客户: null,
    流量池: null,
    广告活动: null,
    消费明细: null,
    设置: null,
    邮箱: null,
    登录密码: null,
    '还没有账户？': null,
    请输入邮箱: null,
    请输入登录密码: null,
    再次输入密码: null,
    '已经有账户？': null,
    立即登录: null,
    请再次输入登录密码: null,
    两次输入密码不一致: null,
    接入流程: null,
    功能特性: null,
    方案定制: null,
    '产品功能丰富，满足用户多样化需求与期望': null,
    控制台: null,
  },
  'en-US': {
    '广告精准触达，效果看得见！': 'Ads with precise targeting, results you can see!',
    '精准投放，智慧营销，让每一次广告展现都能创造价值。': 'Ads with precise targeting, results you can see!',
    立即接入: 'Connect Now',
    每日超过千亿次访问: 'Over a hundred billion visits daily',
    全端流量覆盖: 'Full-platform traffic coverage',
    定制属于你的解决方案: 'Customized solutions tailored to you',
    欢迎通过如下渠道与我们达成联系: 'We welcome you to contact us through the following channels',
    智能评估: 'Intelligent evaluation',
    通过大数据智能评估为您提供精准的分析与全面的决策支持:
      'Providing precise analysis and comprehensive decision support through big data intelligent evaluation',
    专属定制: 'Exclusive customization',
    '贴合需求与个性化方案，专属定制让每一个细节完美呈现':
      'Tailored to your needs and personalized solutions, exclusive customization ensures every detail is perfectly presented',
    渠道整合: 'Channel integration',
    '全面整合多元渠道，提升品牌曝光与市场影响力':
      'Comprehensively integrating multiple channels to enhance brand exposure and market impact',
    高效分发: 'Efficient distribution',
    '快速高效的分发机制，让信息精准到达目标受众':
      'Fast and efficient distribution mechanisms ensure precise delivery to the target audience',
    全端适配: 'Cross-platform compatibility',
    '兼容各大平台，全端适配让用户体验无缝衔接':
      'Compatible with all major platforms, cross-platform compatibility offers a seamless user experience',
    智能优化: 'Smart optimization',
    '利用智能算法实时优化，提升投放效果与资源利用':
      'Utilizing smart algorithms for real-time optimization, improving delivery efficiency and resource utilization',
    数据分析: 'Data analysis',
    '深度数据分析，洞察市场动态，助您做出明智决策':
      'In-depth data analysis to gain market insights and help you make informed decisions',
    灵活投放: 'Flexible delivery',
    '灵活投放策略，精准触达目标用户，提升转化效果':
      'Flexible delivery strategies that precisely reach target users to improve conversion rates',
    精准用户: 'Targeted users',
    '精准定位用户群体，优化资源配置，提升业务效果':
      'Accurately targeting user groups, optimizing resource allocation, and improving business performance',
    金牌客服: 'Premium customer service',
    '金牌客服团队，提供贴心服务，助力客户满意度提升':
      'Our premium customer service team provides attentive service to enhance customer satisfaction',
    多渠道流量整合: 'Multi-channel traffic integration',
    '多个广告渠道的流量整合，提高广告触达率与曝光度。':
      'Integrating traffic from multiple ad channels to increase reach and exposure.',
    精准流量分发: 'Precise traffic distribution',
    '利用智能算法和用户行为数据，精准流量定向分发。':
      'Using intelligent algorithms and user behavior data for precise traffic targeting and distribution.',
    实时数据监控: 'Real-time data monitoring',
    '提供实时的流量监控与数据分析功能，实时反馈优化策略。':
      'Providing real-time traffic monitoring and data analysis, with immediate feedback for optimization strategies.',
    灵活的广告配置: 'Flexible ad configuration',
    '支持多种广告格式和流量分发策略，灵活配置投放规则。':
      'Supports multiple ad formats and traffic distribution strategies, allowing flexible configuration of delivery rules.',
    注册账户: 'Register account',
    创建活动: 'Create campaign',
    任意扩展: 'Extend freely',
    完成接入: 'Complete integration',
    快速接入: 'Fast integration',
    仅需简单几步即可完成流量快速接入: 'Quick traffic integration with just a few simple steps.',
    立即注册: 'Register now',
    '即刻起立即接入, 为业务强劲赋能': 'Start now, integrate instantly to empower your business.',
    退出: 'Logout',
    首页: 'Home',
    登录: 'Login',
    注册: 'Sign up',
    总览: 'Overview',
    客户: 'Clients',
    流量池: 'Traffic pool',
    广告活动: 'Advertising Campaign',
    消费明细: 'Spending details',
    设置: 'Settings',
    邮箱: 'Email',
    登录密码: 'Login password',
    '还没有账户？': "Don't have an account?",
    请输入邮箱: 'Please enter your email',
    请输入登录密码: 'Please enter your login password',
    再次输入密码: 'Re-enter password',
    '已经有账户？': 'Already have an account?',
    立即登录: 'Login now',
    请再次输入登录密码: 'Please re-enter your login password',
    两次输入密码不一致: 'The passwords do not match',
    接入流程: 'Integration Process',
    功能特性: 'Features',
    方案定制: 'Solution Customization',
    '产品功能丰富，满足用户多样化需求与期望': 'Rich product features meet diverse user needs and expectations',
    控制台: 'Console',
  },
  'es-ES': {
    '广告精准触达，效果看得见！': 'Anuncios con orientación precisa, ¡resultados que puedes ver!',
    '精准投放，智慧营销，让每一次广告展现都能创造价值。':
      'Anuncios con orientación precisa, ¡resultados que puedes ver!',
    立即接入: 'Conéctate ahora',
    每日超过千亿次访问: 'Más de cien mil millones de visitas diarias',
    全端流量覆盖: 'Cobertura de tráfico en todas las plataformas',
    定制属于你的解决方案: 'Soluciones personalizadas hechas a medida',
    欢迎通过如下渠道与我们达成联系: 'Te invitamos a contactarnos a través de los siguientes canales',
    智能评估: 'Evaluación inteligente',
    通过大数据智能评估为您提供精准的分析与全面的决策支持:
      'Ofrecemos un análisis preciso y soporte de decisiones a través de la evaluación inteligente con Big Data',
    专属定制: 'Personalización exclusiva',
    '贴合需求与个性化方案，专属定制让每一个细节完美呈现':
      'Soluciones personalizadas que se ajustan a tus necesidades, asegurando que cada detalle se presente perfectamente',
    渠道整合: 'Integración de canales',
    '全面整合多元渠道，提升品牌曝光与市场影响力':
      'Integración completa de múltiples canales para mejorar la exposición de la marca y el impacto en el mercado',
    高效分发: 'Distribución eficiente',
    '快速高效的分发机制，让信息精准到达目标受众':
      'Mecanismos de distribución rápidos y eficientes que garantizan la entrega precisa a la audiencia objetivo',
    全端适配: 'Compatibilidad multiplataforma',
    '兼容各大平台，全端适配让用户体验无缝衔接':
      'Compatible con todas las plataformas principales, la compatibilidad multiplataforma ofrece una experiencia de usuario sin fisuras',
    智能优化: 'Optimización inteligente',
    '利用智能算法实时优化，提升投放效果与资源利用':
      'Utilizando algoritmos inteligentes para optimización en tiempo real, mejorando la eficiencia de la entrega y el uso de recursos',
    数据分析: 'Análisis de datos',
    '深度数据分析，洞察市场动态，助您做出明智决策':
      'Análisis de datos profundos para obtener información del mercado y ayudarte a tomar decisiones informadas',
    灵活投放: 'Entrega flexible',
    '灵活投放策略，精准触达目标用户，提升转化效果':
      'Estrategias de entrega flexibles que alcanzan de manera precisa a los usuarios objetivo, mejorando las tasas de conversión',
    精准用户: 'Usuarios segmentados',
    '精准定位用户群体，优化资源配置，提升业务效果':
      'Segmentación precisa de grupos de usuarios, optimizando la asignación de recursos y mejorando el rendimiento del negocio',
    金牌客服: 'Atención al cliente premium',
    '金牌客服团队，提供贴心服务，助力客户满意度提升':
      'Nuestro equipo de atención al cliente premium ofrece un servicio atento para mejorar la satisfacción del cliente',
    多渠道流量整合: 'Integración de tráfico multicanal',
    '多个广告渠道的流量整合，提高广告触达率与曝光度。':
      'Integración del tráfico de múltiples canales publicitarios para aumentar la tasa de alcance y exposición',
    精准流量分发: 'Distribución precisa del tráfico',
    '利用智能算法和用户行为数据，精准流量定向分发。':
      'Uso de algoritmos inteligentes y datos de comportamiento del usuario para una distribución precisa del tráfico',
    实时数据监控: 'Monitoreo de datos en tiempo real',
    '提供实时的流量监控与数据分析功能，实时反馈优化策略。':
      'Monitoreo de tráfico y análisis de datos en tiempo real, con retroalimentación inmediata para optimizar las estrategias',
    灵活的广告配置: 'Configuración flexible de anuncios',
    '支持多种广告格式和流量分发策略，灵活配置投放规则。':
      'Admite múltiples formatos de anuncios y estrategias de distribución de tráfico, permitiendo una configuración flexible de las reglas de entrega',
    注册账户: 'Registrar cuenta',
    创建活动: 'Crear campaña',
    任意扩展: 'Extender libremente',
    完成接入: 'Completar la integración',
    快速接入: 'Integración rápida',
    仅需简单几步即可完成流量快速接入: 'Integración rápida del tráfico en solo unos simples pasos',
    立即注册: 'Regístrate ahora',
    '即刻起立即接入, 为业务强劲赋能': 'Comienza ahora, integra instantáneamente para impulsar tu negocio',
    退出: 'Cerrar sesión',
    首页: 'Inicio',
    登录: 'Iniciar sesión',
    注册: 'Registrarse',
    总览: 'Visión general',
    客户: 'Clientes',
    流量池: 'Pool de tráfico',
    广告活动: 'Campaña Publicitaria',
    消费明细: 'Detalles de consumo',
    设置: 'Configuración',
    邮箱: 'Correo electrónico',
    登录密码: 'Contraseña de inicio de sesión',
    '还没有账户？': '¿No tienes una cuenta?',
    请输入邮箱: 'Por favor, introduce tu correo electrónico',
    请输入登录密码: 'Por favor, introduce tu contraseña',
    再次输入密码: 'Reingresa la contraseña',
    '已经有账户？': '¿Ya tienes una cuenta?',
    立即登录: 'Inicia sesión ahora',
    请再次输入登录密码: 'Por favor, reingresa tu contraseña',
    两次输入密码不一致: 'Las contraseñas no coinciden',
    接入流程: 'Proceso de integración',
    功能特性: 'Características',
    方案定制: 'Personalización de soluciones',
    '产品功能丰富，满足用户多样化需求与期望':
      'Las características ricas del producto satisfacen las diversas necesidades y expectativas de los usuarios',
    控制台: 'Consola',
  },
  'ja-JP': {
    '广告精准触达，效果看得见！': 'ターゲットを絞った広告、効果が見える！',
    '精准投放，智慧营销，让每一次广告展现都能创造价值。': 'ターゲットを絞った広告、効果が見える！',
    立即接入: '今すぐ接続',
    每日超过千亿次访问: '毎日千億回以上の訪問',
    全端流量覆盖: '全プラットフォームのトラフィックをカバー',
    定制属于你的解决方案: 'あなたに合わせたカスタマイズされたソリューション',
    欢迎通过如下渠道与我们达成联系: '以下のチャネルからご連絡ください',
    智能评估: 'スマート評価',
    通过大数据智能评估为您提供精准的分析与全面的决策支持:
      'ビッグデータを使ったスマート評価で、正確な分析と包括的な意思決定サポートを提供します',
    专属定制: '専用カスタマイズ',
    '贴合需求与个性化方案，专属定制让每一个细节完美呈现':
      'ニーズに合わせたパーソナライズされたソリューションで、各ディテールが完璧に提供されます',
    渠道整合: 'チャネル統合',
    '全面整合多元渠道，提升品牌曝光与市场影响力': '多様なチャネルを統合し、ブランドの露出と市場での影響力を高めます',
    高效分发: '効率的な配信',
    '快速高效的分发机制，让信息精准到达目标受众':
      '迅速かつ効率的な配信メカニズムで、ターゲットオーディエンスに正確に情報を届けます',
    全端适配: '全プラットフォーム対応',
    '兼容各大平台，全端适配让用户体验无缝衔接':
      '主要なプラットフォームに対応し、全端対応でユーザー体験をシームレスに提供',
    智能优化: 'スマート最適化',
    '利用智能算法实时优化，提升投放效果与资源利用':
      'スマートアルゴリズムを利用してリアルタイムで最適化し、配信の効果とリソースの利用を向上させます',
    数据分析: 'データ分析',
    '深度数据分析，洞察市场动态，助您做出明智决策':
      '詳細なデータ分析で市場動向を洞察し、賢明な意思決定をサポートします',
    灵活投放: '柔軟な配信',
    '灵活投放策略，精准触达目标用户，提升转化效果':
      '柔軟な配信戦略で、ターゲットユーザーに正確にアプローチし、コンバージョン効果を向上させます',
    精准用户: 'ターゲットユーザー',
    '精准定位用户群体，优化资源配置，提升业务效果':
      'ターゲットユーザーを正確に特定し、リソース配分を最適化し、ビジネス効果を向上させます',
    金牌客服: '優秀なカスタマーサービス',
    '金牌客服团队，提供贴心服务，助力客户满意度提升':
      '優秀なカスタマーサービスチームが、丁寧なサービスを提供し、顧客満足度の向上をサポートします',
    多渠道流量整合: '多チャネルのトラフィック統合',
    '多个广告渠道的流量整合，提高广告触达率与曝光度。':
      '複数の広告チャネルのトラフィックを統合し、広告の到達率と露出度を向上させます',
    精准流量分发: '正確なトラフィック配信',
    '利用智能算法和用户行为数据，精准流量定向分发。':
      'スマートアルゴリズムとユーザー行動データを活用して、正確なトラフィックのターゲティング配信を行います',
    实时数据监控: 'リアルタイムデータモニタリング',
    '提供实时的流量监控与数据分析功能，实时反馈优化策略。':
      'リアルタイムのトラフィックモニタリングとデータ分析機能を提供し、リアルタイムのフィードバックで最適化戦略をサポートします',
    灵活的广告配置: '柔軟な広告設定',
    '支持多种广告格式和流量分发策略，灵活配置投放规则。':
      '多様な広告フォーマットとトラフィック配信戦略をサポートし、柔軟に配信ルールを設定できます',
    注册账户: 'アカウント登録',
    创建活动: 'キャンペーンを作成',
    任意扩展: '自由に拡張',
    完成接入: '接続を完了',
    快速接入: '迅速な接続',
    仅需简单几步即可完成流量快速接入: '数ステップで迅速にトラフィックの接続が完了します',
    立即注册: '今すぐ登録',
    '即刻起立即接入, 为业务强劲赋能': '今すぐ接続し、ビジネスの成長を強力にサポート',
    退出: 'ログアウト',
    首页: 'ホームページ',
    登录: 'ログイン',
    注册: '登録',
    总览: '概要',
    客户: '顧客',
    流量池: 'トラフィックプール',
    广告活动: '広告キャンペーン',
    消费明细: '消費明細',
    设置: '設定',
    邮箱: 'メールアドレス',
    登录密码: 'ログインパスワード',
    '还没有账户？': 'アカウントがまだありませんか？',
    请输入邮箱: 'メールアドレスを入力してください',
    请输入登录密码: 'ログインパスワードを入力してください',
    再次输入密码: 'パスワードを再入力',
    '已经有账户？': '既にアカウントがありますか？',
    立即登录: '今すぐログイン',
    请再次输入登录密码: 'ログインパスワードを再度入力してください',
    两次输入密码不一致: '2回入力したパスワードが一致しません',
    接入流程: '導入プロセス',
    功能特性: '機能特性',
    方案定制: 'ソリューションカスタマイズ',
    '产品功能丰富，满足用户多样化需求与期望': '製品機能が豊富で、ユーザーの多様なニーズと期待に応えます',
    控制台: 'コンソール',
  },
  'ko-KR': {
    '广告精准触达，效果看得见！': '정확한 광고 타겟팅, 결과가 눈에 보입니다!',
    '精准投放，智慧营销，让每一次广告展现都能创造价值。': '정확한 광고 타겟팅, 결과가 눈에 보입니다!',
    立即接入: '지금 접속하세요',
    每日超过千亿次访问: '하루 1조 이상의 방문',
    全端流量覆盖: '모든 플랫폼의 트래픽을 커버',
    定制属于你的解决方案: '맞춤형 솔루션 제공',
    欢迎通过如下渠道与我们达成联系: '다음 채널을 통해 문의해 주세요',
    智能评估: '스마트 평가',
    通过大数据智能评估为您提供精准的分析与全面的决策支持:
      '빅데이터를 활용한 스마트 평가로 정확한 분석 및 종합적인 의사결정 지원 제공',
    专属定制: '맞춤형 서비스',
    '贴合需求与个性化方案，专属定制让每一个细节完美呈现':
      '요구에 맞춘 개인화된 솔루션으로 모든 세부 사항을 완벽하게 구현',
    渠道整合: '채널 통합',
    '全面整合多元渠道，提升品牌曝光与市场影响力': '다양한 채널을 통합하여 브랜드 노출 및 시장 영향력 강화',
    高效分发: '효율적인 배포',
    '快速高效的分发机制，让信息精准到达目标受众': '빠르고 효율적인 배포 메커니즘으로 정보를 정확히 타겟층에게 전달',
    全端适配: '모든 플랫폼에 적응',
    '兼容各大平台，全端适配让用户体验无缝衔接':
      '모든 주요 플랫폼과 호환되며, 전 플랫폼 적응으로 매끄러운 사용자 경험 제공',
    智能优化: '스마트 최적화',
    '利用智能算法实时优化，提升投放效果与资源利用':
      '스마트 알고리즘을 사용하여 실시간 최적화를 통해 배포 효과와 자원 활용을 극대화',
    数据分析: '데이터 분석',
    '深度数据分析，洞察市场动态，助您做出明智决策':
      '심층 데이터 분석을 통해 시장 동향을 파악하고 현명한 의사결정을 지원',
    灵活投放: '유연한 배포',
    '灵活投放策略，精准触达目标用户，提升转化效果':
      '유연한 배포 전략으로 타겟 사용자에게 정확히 도달하고 전환 효과를 증대',
    精准用户: '정확한 타겟팅',
    '精准定位用户群体，优化资源配置，提升业务效果':
      '사용자 그룹을 정확하게 타겟팅하여 자원 배분을 최적화하고 비즈니스 성과를 향상',
    金牌客服: '프리미엄 고객 지원',
    '金牌客服团队，提供贴心服务，助力客户满意度提升':
      '프리미엄 고객 지원팀이 세심한 서비스를 제공하여 고객 만족도를 높입니다',
    多渠道流量整合: '다중 채널 트래픽 통합',
    '多个广告渠道的流量整合，提高广告触达率与曝光度。': '여러 광고 채널의 트래픽을 통합하여 광고 도달률 및 노출도 향상',
    精准流量分发: '정확한 트래픽 배포',
    '利用智能算法和用户行为数据，精准流量定向分发。':
      '스마트 알고리즘과 사용자 행동 데이터를 활용하여 정확한 트래픽 타겟팅 배포',
    实时数据监控: '실시간 데이터 모니터링',
    '提供实时的流量监控与数据分析功能，实时反馈优化策略。':
      '실시간 트래픽 모니터링 및 데이터 분석 기능을 제공하며, 실시간 피드백을 통한 최적화 전략',
    灵活的广告配置: '유연한 광고 설정',
    '支持多种广告格式和流量分发策略，灵活配置投放规则。':
      '다양한 광고 형식과 트래픽 배포 전략을 지원하며, 유연한 배포 규칙 설정',
    注册账户: '계정 등록',
    创建活动: '캠페인 만들기',
    任意扩展: '자유 확장',
    完成接入: '통합 완료',
    快速接入: '빠른 통합',
    仅需简单几步即可完成流量快速接入: '몇 가지 간단한 단계만으로 트래픽 통합을 완료할 수 있습니다',
    立即注册: '지금 등록',
    '即刻起立即接入, 为业务强劲赋能': '지금부터 바로 통합하여 비즈니스에 강력한 성능을 더하세요',
    退出: '로그아웃',
    首页: '홈페이지',
    登录: '로그인',
    注册: '회원 가입',
    总览: '개요',
    客户: '고객',
    流量池: '트래픽 풀',
    广告活动: '광고 캠페인',
    消费明细: '소비 내역',
    设置: '설정',
    邮箱: '이메일',
    登录密码: '로그인 비밀번호',
    '还没有账户？': '계정이 없으신가요?',
    请输入邮箱: '이메일을 입력해주세요',
    请输入登录密码: '로그인 비밀번호를 입력해주세요',
    再次输入密码: '비밀번호를 다시 입력해주세요',
    '已经有账户？': '이미 계정이 있으신가요?',
    立即登录: '지금 로그인',
    请再次输入登录密码: '로그인 비밀번호를 다시 입력해주세요',
    两次输入密码不一致: '두 번 입력한 비밀번호가 일치하지 않습니다',
    接入流程: '접속 절차',
    功能特性: '기능 특징',
    方案定制: '솔루션 맞춤화',
    '产品功能丰富，满足用户多样化需求与期望': '제품 기능이 풍부하여 다양한 사용자 요구와 기대를 충족합니다',
    控制台: '콘솔',
  },
  'ar-SA': {
    '广告精准触达，效果看得见！': 'إعلانات دقيقة تصل إلى الجمهور المستهدف، والنتائج واضحة!',
    '精准投放，智慧营销，让每一次广告展现都能创造价值。': 'توزيع دقيق، تسويق ذكي، يجعل كل عرض للإعلانات يخلق قيمة.',
    立即接入: 'ابدأ الآن',
    每日超过千亿次访问: 'أكثر من تريليون زيارة يومياً',
    全端流量覆盖: 'تغطية شاملة لجميع قنوات الحركة',
    定制属于你的解决方案: 'حلول مخصصة لك',
    欢迎通过如下渠道与我们达成联系: 'يرجى التواصل معنا من خلال القنوات التالية',
    智能评估: 'تقييم ذكي',
    通过大数据智能评估为您提供精准的分析与全面的决策支持:
      'يقدم لك تقييم الذكاء الاصطناعي المستند إلى البيانات الضخمة تحليلاً دقيقاً ودعماً كاملاً لاتخاذ القرار.',
    专属定制: 'تخصيص حصري',
    '贴合需求与个性化方案，专属定制让每一个细节完美呈现':
      'تخصيص حصري يتناسب مع احتياجاتك وخطة مخصصة تظهر كل التفاصيل بشكل مثالي.',
    渠道整合: 'دمج القنوات',
    '全面整合多元渠道，提升品牌曝光与市场影响力':
      'دمج شامل للقنوات المتعددة لزيادة تعرض العلامة التجارية وتأثيرها في السوق.',
    高效分发: 'توزيع فعال',
    '快速高效的分发机制，让信息精准到达目标受众':
      'آلية توزيع سريعة وفعالة تجعل المعلومات تصل بدقة إلى الجمهور المستهدف.',
    全端适配: 'توافق شامل مع جميع الأجهزة',
    '兼容各大平台，全端适配让用户体验无缝衔接': 'توافق مع جميع المنصات الرئيسية، ما يوفر تجربة سلسة للمستخدم.',
    智能优化: 'تحسين ذكي',
    '利用智能算法实时优化，提升投放效果与资源利用':
      'استخدام الخوارزميات الذكية للتحسين في الوقت الحقيقي لزيادة فعالية التوزيع واستغلال الموارد.',
    数据分析: 'تحليل البيانات',
    '深度数据分析，洞察市场动态，助您做出明智决策':
      'تحليل بيانات عميق يكشف عن ديناميكيات السوق ويساعدك على اتخاذ قرارات حكيمة.',
    灵活投放: 'توزيع مرن',
    '灵活投放策略，精准触达目标用户，提升转化效果':
      'استراتيجيات توزيع مرنة تستهدف المستخدمين بدقة وتحسن معدلات التحويل.',
    精准用户: 'استهداف المستخدمين بدقة',
    '精准定位用户群体，优化资源配置，提升业务效果':
      'تحديد دقيق للمجموعات المستهدفة من المستخدمين لتحسين توزيع الموارد وزيادة فعالية الأعمال.',
    金牌客服: 'خدمة عملاء مميزة',
    '金牌客服团队，提供贴心服务，助力客户满意度提升':
      'فريق خدمة العملاء المتميز يقدم خدمة شخصية تساعد في رفع مستوى رضا العملاء.',
    多渠道流量整合: 'دمج حركة المرور عبر قنوات متعددة',
    '多个广告渠道的流量整合，提高广告触达率与曝光度。':
      'دمج حركة المرور من عدة قنوات إعلانية لتحسين معدلات الوصول والظهور.',
    精准流量分发: 'توزيع حركة المرور بدقة',
    '利用智能算法和用户行为数据，精准流量定向分发。':
      'استخدام الخوارزميات الذكية وبيانات سلوك المستخدم لتوزيع حركة المرور بدقة.',
    实时数据监控: 'مراقبة البيانات في الوقت الفعلي',
    '提供实时的流量监控与数据分析功能，实时反馈优化策略。':
      'توفير مراقبة لحركة المرور وتحليل البيانات في الوقت الفعلي، وتقديم استراتيجيات تحسين بناءً على التغذية الراجعة الفورية.',
    灵活的广告配置: 'إعدادات إعلانات مرنة',
    '支持多种广告格式和流量分发策略，灵活配置投放规则。':
      'دعم صيغ إعلانية متعددة واستراتيجيات توزيع حركة المرور مع إعدادات توزيع مرنة.',
    注册账户: 'تسجيل حساب',
    创建活动: 'إنشاء حملة',
    任意扩展: 'توسع بدون حدود',
    完成接入: 'إتمام الربط',
    快速接入: 'ربط سريع',
    仅需简单几步即可完成流量快速接入: 'لا يتطلب سوى خطوات بسيطة لإكمال ربط الحركة بسرعة.',
    立即注册: 'سجل الآن',
    '即刻起立即接入, 为业务强劲赋能': 'ابدأ الربط الآن لتعزيز أعمالك بقوة.',
    退出: 'تسجيل خروج',
    首页: 'الصفحة الرئيسية',
    登录: 'تسجيل الدخول',
    注册: 'تسجيل',
    总览: 'نظرة عامة',
    客户: 'العملاء',
    流量池: 'مجموعة الحركة',
    广告活动: 'حملة إعلانية',
    消费明细: 'تفاصيل الاستهلاك',
    设置: 'الإعدادات',
    邮箱: 'البريد الإلكتروني',
    登录密码: 'كلمة المرور',
    '还没有账户？': 'ليس لديك حساب؟',
    请输入邮箱: 'يرجى إدخال البريد الإلكتروني',
    请输入登录密码: 'يرجى إدخال كلمة المرور',
    再次输入密码: 'أعد إدخال كلمة المرور',
    '已经有账户？': 'هل لديك حساب بالفعل؟',
    立即登录: 'سجل الدخول الآن',
    请再次输入登录密码: 'يرجى إعادة إدخال كلمة المرور',
    两次输入密码不一致: 'كلمتا المرور غير متطابقتين',
    接入流程: 'عملية التكامل',
    功能特性: 'الميزات',
    方案定制: 'تخصيص الحلول',
    '产品功能丰富，满足用户多样化需求与期望': 'ميزات المنتج الغنية تلبي احتياجات وتوقعات المستخدمين المتنوعة',
    控制台: 'وحدة التحكم',
  },
};

// useLocale Hook 实现
export const useLocale = (): { t: (key: string) => string; locale: string; setLocale: (key: string) => void } => {
  const [locale, setLocale] = useState('');
  // const [locale, setLocale] = useState('ko-KR'); // @TODO: 测试时指定语言

  // 根据浏览器语言设定默认语言
  // useEffect(() => {
  //   /**
  //    * 优先获取传参指定语言
  //    */
  //   let lang = new URLSearchParams(window.location.search).get('language');

  //   // 如果有内容则保存至 localStorage
  //   if (lang) localStorage.setItem('language', lang)

  //   /**
  //    * 其次获取 localStorage 语言
  //    */
  //   if (!lang) lang = localStorage.getItem('language')

  //   /**
  //    * 获取浏览器语言
  //    */
  //   if (!lang) lang = navigator.language

  //   if (lang.startsWith('zh')) {
  //     setLocale('zh-CN');
  //   } else if (lang.startsWith('en')) {
  //     setLocale('en-US');
  //   } else if (lang.startsWith('es')) {
  //     setLocale('es-ES');
  //   } else if (lang.startsWith('ja')) {
  //     setLocale('ja-JP');
  //   } else if (lang.startsWith('ko')) {
  //     setLocale('ko-KR');
  //   } else if (lang.startsWith('ar')) {
  //     setLocale('ar-SA');
  //   } else {
  //     setLocale('en-US')
  //   }

  //   // @TODO: 可以根据需要增加更多语言判断
  //   // ...

  //   console.log('Current language: ', lang)
  // }, []);

  // 返回一个翻译函数，接受键值并返回对应语言内容, 若不存在则返回 key 本身
  return {
    t: (key: string): string => {
      let lang = locale;

      if (!lang) {
        /**
         * 优先获取传参指定语言
         */
        lang = new URLSearchParams(window.location.search).get('language') ?? '';

        // 如果有内容则保存至 localStorage
        if (lang) localStorage.setItem('language', lang);

        /**
         * 其次获取 localStorage 语言
         */
        if (!lang) lang = localStorage.getItem('language') ?? '';

        /**
         * 获取浏览器语言
         */
        if (!lang) lang = navigator.language;

        if (lang.startsWith('zh')) {
          setLocale('zh-CN');
        } else if (lang.startsWith('en')) {
          setLocale('en-US');
        } else if (lang.startsWith('es')) {
          setLocale('es-ES');
        } else if (lang.startsWith('ja')) {
          setLocale('ja-JP');
        } else if (lang.startsWith('ko')) {
          setLocale('ko-KR');
        } else if (lang.startsWith('ar')) {
          setLocale('ar-SA');
        } else {
          setLocale('en-US');
        }
      }

      return (languagePack[lang] || {})[key] ?? key;
    },
    locale,
    setLocale,
  };
};
