import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Reset CSS
import 'normalize.css/normalize.css';

// Layout
import Layout from '@/layouts';

// Global Pages
import Error from '@/layouts/pages/Error';
import NotFound from '@/layouts/pages/NotFound';

import Config from '@/config';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Layout />,
      errorElement: <Error />,
      children: [
        {
          index: true,
          lazy: async () => await import('@/pages/Home'),
        },
        {
          path: 'login',
          lazy: async () => await import('@/pages/Login'),
        },
        {
          path: 'register',
          lazy: async () => await import('@/pages/Register'),
        },
        {
          path: '/dashboard',
          children: [
            {
              index: true,
              lazy: async () => await import('@/pages/Dashboard/Overview'),
            },
            {
              path: 'settings',
              lazy: async () => await import('@/pages/Dashboard/Setting'),
            },
            {
              path: 'traffic',
              lazy: async () => await import('@/pages/Common/Traffic'),
            },
            {
              path: 'forwards/:forward_uuid?',
              lazy: async () => await import('@/pages/Common/Forward'),
            },
            {
              path: 'trades/:trade_uuid?',
              lazy: async () => await import('@/pages/Common/Trade'),
            },
          ],
        },
        {
          path: '/895Hgq5RQ1FpOyWq',
          children: [
            {
              index: true,
              lazy: async () => await import('@/pages/Manager/Overview'),
            },
            {
              path: 'traffic',
              lazy: async () => await import('@/pages/Common/Traffic'),
            },
            {
              path: 'pools/:pool_uuid?',
              lazy: async () => await import('@/pages/Manager/Pool'),
            },
            {
              path: 'settings',
              lazy: async () => await import('@/pages/Manager/Setting'),
            },
            {
              path: 'users/:user_uuid?',
              lazy: async () => await import('@/pages/Manager/User'),
            },
            {
              path: 'forwards/:forward_uuid?',
              lazy: async () => await import('@/pages/Common/Forward'),
            },
            {
              path: 'trades/:trade_uuid?',
              lazy: async () => await import('@/pages/Common/Trade'),
            },
          ],
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ],
  {
    basename: Config.basePath,
  },
);

ReactDOM.createRoot(document.getElementById('traffic-forwarder') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
