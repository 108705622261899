import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export default class User {
  credit = 0;

  constructor() {
    /* eslint-disable */
    makeAutoObservable(this, {
      credit: observable,
      update: action,
    });

    // 持久存储
    makePersistable(this, {
      name: `TrafficForwarder-${this.constructor.name}`,
      properties: ['credit'],
      storage: window.localStorage,
    });
  }

  update(credit: number): void {
    this.credit = credit > 0 ? credit : 0
  }

  clear() {
    this.credit = 0
  }
}
