import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export default class Auth {
  type = '';
  token = '';
  username = '';

  constructor() {
    /* eslint-disable */
    makeAutoObservable(this, {
      type: observable,
      token: observable,
      username: observable,
      update: action,
      clear: action,
    });

    // 持久存储
    makePersistable(this, {
      name: `TrafficForwarder-${this.constructor.name}`,
      properties: ['type', 'token', 'username'],
      storage: window.localStorage,
    });
  }

  update(type: string, token: string, username: string) {
    this.type = type;
    this.token = token;
    this.username = username;

    localStorage.setItem('token_type', type);
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
  }

  clear() {
    console.log('Auth cleared')
    
    this.type = '';
    this.token = '';
    this.username = '';
  }
}
