import { version } from '@/../package.json';

export default {
  // basePath: '',
  basePath: '/',
  // basePath: '/apps/traffic-forwarder/dashboard',
  app: {
    name: 'traffic-forwarder',
    brand: 'BOOT',
    subBrand: 'ADS',
  },
  company: {
    name: 'BootADS Technology Co., Ltd.',
    enName: 'BootADS Technology Co., Ltd.',
  },
  version,
};
